import httpService from "@/plugins/http";
export default {
  data() {
    return {
      loading: false,
      finished: false,
      titleTwo: "请选择",
      titleThree: "请选择",
      baseUrl: process.env.VUE_APP_API_URL,
      forms: {},
      levelOne: 0,
      oneList: [],
      twoList: [],
      threeList: [],
      allTreeList: [],
      areaIdList: [],
      dataList: [],
      pageSize: 10,
      currentPage: 1,
      total: 0, // 总条数
      parentNodes: [], // 当前用户所在的父节点
    };
  },
  methods: {
    // getParentNodes(arr1, id) {
    //   var temp = [];
    //   var forFn = function (arr, id) {
    //     for (var i = 0; i < arr.length; i++) {
    //       var item = arr[i];
    //       if (item.id === id) {
    //         forFn(arr1, item.parent);
    //         temp.push(item);
    //         break;
    //       } else {
    //         if (Array.isArray(item.node) && item.node.length) {
    //           forFn(item.node, id);
    //         }
    //       }
    //     }
    //   };
    //   forFn(arr1, id);
    //   return temp;
    // },
    async getTree() {
      try {
        this.$store.commit("showLoading");
        this.area_id = this.$store.state.userInfo ? this.$store.state.userInfo.area.manage : [];
        const res = await httpService.post("/api/area/tree", {});
        if (res.code === 0) {
          this.allTreeList = res.data || [];
          this.oneList = []; //镇
          this.areaIdList = [...this.area_id]; // 默认用户返回的id
          res.data.forEach((item) => {
            this.oneList.push({
              text: item.name,
              value: item.id,
            });
          });
          if (this.oneList.length > 0) {
            //默认一个镇
            this.forms.levelOne = this.oneList[0].value;
            this.handleChangeOne(this.forms.levelOne);
          }
          console.log(this.areaIdList, "this.areaIdList");
          //列表信息
          this.getInit();
        } else {
          this.$store.commit("hideLoading");
        }
      } catch (error) {
        console.log(error, "error");
      } finally {
        this.$store.commit("hideLoading");
      }
    },
    handleChangeOne(code) {
      this.twoList = []; //村
      this.allTreeList.forEach((item1) => {
        if (item1.id === code) {
          if (item1.node) {
            this.twoList = item1.node.map((item2) => {
              return {
                ...item2,
                text: item2.name,
                value: item2.id,
              };
            });
          }
        }
      });
    },
    handleChangeTwo(code) {
      let obj = this.twoList.find((item) => {
        return item.value === code;
      });
      this.threeList = []; //组
      this.areaIdList = [];
      if (obj) {
        this.titleTwo = obj.text;
        this.titleThree = "请选择";
      } else {
        this.areaIdList = [...this.area_id]
      }

      this.allTreeList.forEach((item1) => {
        item1.node.forEach((item2) => {
          if (item2.id === code) {
            if (item2.node) {
              item2.node.forEach((item3) => {
                if (this.area_id.indexOf(item3.id) > -1) {
                  this.threeList.push({
                    ...item3,
                    text: item3.name,
                    value: item3.id,
                  });
                }
              });
            }
          }
        });
      });
      this.threeList.forEach((item) => {
        this.areaIdList.push(item.value);
      });
      // 如果没有3级 就传当前id
      if (!this.areaIdList.length) {
        this.areaIdList = [code]
      }
      // this.areaIdList.unshift(code);
      this.currentReset();
      this.getInit();
    },
    handleChangeThree(code) {
      let obj = this.threeList.find((item) => {
        return item.value === code;
      });
      if (obj) {
        this.titleThree = obj.text;
      }
      this.areaIdList = [code];
      console.log(this.areaIdList, "change3");
      this.currentReset();
      this.getInit();
    },
    async getInit() {
      try {
        if (this.currentPage === 1) {
          this.$store.commit("showLoading");
        }
        const res = await httpService.post(this.interfaceUrl, {
          area_id: this.areaIdList,
          name: this.forms.name,
          hh: this.forms.name,
          start_page: this.currentPage, //开始页0开始
          size_per_page: this.pageSize,
        });
        if (res.code === 0) {
          if (this.currentPage === 1) {
            this.dataList = res.data || [];
          } else {
            let records = res.data || [];
            this.dataList = this.dataList.concat(records);
          }
          this.total = Math.ceil(res.total / this.pageSize);
          this.currentPage = this.currentPage + 1;
          this.loading = false;
        } else {
          this.$store.commit("hideLoading");
        }
      } catch (error) {
        console.log(error, "error");
      } finally {
        this.$store.commit("hideLoading");
      }
    },
    onLoad() {
      // 异步更新数据
      if (this.currentPage === 1) {
        this.getInit();
      } else {
        if (this.currentPage <= this.total) {
          this.getInit();
        } else {
          this.finished = true;
        }
      }
    },
    handleClose() {
      this.forms.levelTwo = "";
      this.forms.levelThree = "";
      this.threeList = [];
      this.titleTwo = "请选择";
      this.titleThree = "请选择";
      this.currentReset();
      this.getTree();
    },
    onSearch(val) {
      this.forms.name = val;
      this.currentReset();
      this.getInit();
    },
    onClear() {
      this.forms.name = "";
      this.currentReset();
      this.getInit();
    },
    currentReset() {
      this.currentPage = 1;
      this.total = 0;
      this.loading = false;
      this.finished = false;
      this.dataList = [];
    },
  },
  mounted() {
    this.getTree();
  },
};
